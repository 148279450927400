import { Controller } from 'react-hook-form';
import { createFilterOptions } from '@mui/base';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useState } from 'react';

const filter = createFilterOptions();

export default function AuraFreeSoloControlledAutocomplete({
  onChange: ignored,
  control,
  controlName,
  inputValues,
  labelName,
  readOnly,
  hideClearable = false,
  onUpdate = null,
  textClassName = null,
}) {
  const [open, setOpen] = useState(false);
  const classNames = textClassName || 'mt-8 mb-16';
  return (
    <Controller
      render={({ field: { onChange, value, ...props } }) => (
        <Autocomplete
          readOnly={readOnly}
          fullWidth
          onChange={(event, newValue) => {
            onChange(newValue);
            if (onUpdate) {
              onUpdate(event, newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.name);
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                name: `Add "${inputValue}"`,
              });
            }
            return filtered;
          }}
          open={open}
          onOpen={() => !readOnly && setOpen(true)}
          onClose={() => setOpen(false)}
          disableClearable={readOnly || hideClearable}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          value={value}
          options={inputValues}
          getOptionLabel={(option) => {
            if (option) {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              if (option.name) {
                return option.name;
              }
              return '';
            }
            return '';
          }}
          renderOption={(props1, option) => <li {...props1}>{typeof option === 'string' ? option : option.name}</li>}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label={labelName}
              margin="normal"
              variant="outlined"
              className={classNames}
              fullWidth
              size="small"
              InputProps={{ ...params.InputProps, readOnly }}
            />
          )}
          {...props}
        />
      )}
      onChange={([, data]) => data}
      control={control}
      name={controlName}
    />
  );
}
